.App {
  text-align: center;
  margin: 0 10%;
}

.name {
  color: #4bfaeb;
  font-weight: bolder;
}

.intro {
  font-size: 3rem;
  margin-bottom: 2rem;
}

.Typewriter {
  margin-top: 2rem;
}

.Typewriter__wrapper {
  font-size: 2rem;
  color: #d1d1d1;
}

@media (max-width: 380px) {
  .Typewriter__wrapper {
    font-size: 1.6rem;
  }
  .intro {
    font-size: 2.4rem;
  }
  .Typewriter {
    margin-top: 2.4rem;
  }
}

.Typewriter__cursor {
  font-size: 2rem;
}

.wave {
  font-size: 3rem;
  margin: 2rem;
}

[data-typeit-id="6305873"].ti-cursor {
  color: var(--ti-color, #4bfaeb);
}
